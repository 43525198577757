import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Form from '../../components/Form'

import service3 from '../../images/services-image3.jpg'


const PageThree = () => {
    return (
        <Layout>
        <SEO title='Accountant in Annapolis Maryland' />    
            <section>
                <div className='service-page-header'>
                    <div className='service-page-header-left'>
                        <h1 style={{color: `#B62020`}}>PROFESSIONAL COURTESY</h1>
                        <p>
                        Regardless of the type of accounting service, all accountants generally help in the 
                        assessment of finances of a given business, bookkeeping and recording and analyzing all business 
                        transactions. The accounting services differ depending on experience, business environment, and 
                        management status. It’s thus essential to know the appropriate type of accounting service that 
                        your business may require. 
                        <br/>
                        <br/>
                        <h3 style={{color: `#B62020`}}>YOU HAVE MORE IMPORTANT THINGS TO DO</h3>
                        When it comes down to it, the biggest benefit that working with a professional accountant gives you is peace of mind. You 
                        know that your record-keeping and tax filing are taken care of, so you have one less thing to worry about.
                        Instead of having to constantly look over the shoulder of another employee tasked to figure out the process or even take 
                        on this burden yourself, you can let the accountant handle everything and report back to you when they’re finished. 
                        As a small business owner, your time is valuable, and you need to be choosing carefully where to focus your energies. With 
                        an accountant handling your taxes, you as the owner can focus on the core vision of your business. 
                        You can speak to me regarding any necessary accounting or tax services to comply with laws or government regulations for 
                        yourself or your company.
                        </p>
                    </div>
                    <div className='service-page-header-right'>
                        <div className='service-page-header-photo-container'>
                            <img src={service3} alt='fix this alt'/>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='contact-section'>
                    <div className='contact-section-top'>
                    <p style={{margin: `0`}}>CONTACT</p>
                    </div>
                    <div className='contact-section-bottom'>
                    <div className='contact-section-bottom__left'>
                        <p>
                        Contact me by phone at <span style={{color: `#B62020`}}>(410)271-8259</span> 
                        <br/>
                        <br/>
                        or
                        <br/>
                        <br/>
                        Fill out the contact form and I'll reach out to you as soon as possible.
                        </p>
                    </div>
                    <div className='contact-section-bottom__right'>
                        <Form />
                    </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PageThree